import * as React from "react";
import { Card } from "../../../ui-library/cards/card";
import Row from "../../../ui-library/column-system/row";
import {
  formatPercent,
  formatBigCurrency,
} from "../../../../utils/format-number";
import { SiteContext } from "../../../../site-context/site-context";
import { useMarketGlobal } from "../../../../hooks/query-hooks/use-market-global";
import TrendText from "../../../ui-library/text/trend-text";
import TextSkeleton from "../../../ui-library/text/text-skeleton";
import styles from "./market-snapshot.styles";
import SnapshotItem from "./snapshot-item";

const CoinMarketSnapshot: React.FC = () => {
  const {
    currency: [currency],
  } = React.useContext(SiteContext);

  const { data, loading } = useMarketGlobal();

  const formatDisplayValue = (value: number) =>
    formatBigCurrency({
      value: value,
      currency,
    });

  if (loading && !data) {
    return (
      <Card css={styles.card}>
        <Row>
          <SnapshotItem label="Crypto Market Cap">
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="BTC Market Cap">
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="24hr Market Cap Change" isBottom={true}>
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="BTC Dominance" isBottom={true}>
            <TextSkeleton width={100} />
          </SnapshotItem>
        </Row>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  const {
    totalMarketCap,
    marketCapPercentage,
    marketCapChangePercentage24hUsd,
  } = data;

  const cryptoMarketCap = totalMarketCap ? totalMarketCap[currency] : undefined;
  const btcMarketPercentage = marketCapPercentage?.btc;
  const btcMarketCap =
    cryptoMarketCap && btcMarketPercentage
      ? (cryptoMarketCap * btcMarketPercentage) / 100
      : undefined;

  return (
    <Card css={styles.card}>
      <Row>
        {cryptoMarketCap && (
          <SnapshotItem label="Crypto Market Cap">
            {formatDisplayValue(cryptoMarketCap) || "-"}
          </SnapshotItem>
        )}
        {btcMarketCap && (
          <SnapshotItem label="BTC Market Cap">
            {formatDisplayValue(btcMarketCap) || "-"}
          </SnapshotItem>
        )}
        {marketCapChangePercentage24hUsd && (
          <SnapshotItem label="24hr Market Cap Change" isBottom={true}>
            <TrendText
              value={marketCapChangePercentage24hUsd}
              arrowSpacing={6}
            />
          </SnapshotItem>
        )}
        {btcMarketPercentage && (
          <SnapshotItem label="BTC Dominance" isBottom={true}>
            {formatPercent({ value: btcMarketPercentage })}
          </SnapshotItem>
        )}
      </Row>
    </Card>
  );
};

export default CoinMarketSnapshot;
