import * as React from "react";
import { LinkCard } from "../card";
import { buildCoinUrl } from "../../../../utils/build-url";
import { EventCategory } from "../../../../utils/analytics";
import Image from "../../image/image";
import TrendText from "../../text/trend-text";
import styles from "./featured-coin-card.styles";

interface FeaturedCoinCardProps {
  slug: string;
  symbol: string;
  name: string;
  image: string;
  priceChangePercentage24h?: number;
  eventCategory: EventCategory;
}

const FeaturedCoinCard: React.FC<FeaturedCoinCardProps> = ({
  slug,
  name,
  symbol,
  image,
  priceChangePercentage24h,
  eventCategory,
}) => {
  return (
    <LinkCard
      href={buildCoinUrl(slug)}
      css={styles.card}
      analytics={{
        category: eventCategory,
        action: `Click - ${name}`,
        label: name,
      }}
    >
      <div css={styles.imageContainer}>
        <Image
          src={image}
          alt={name}
          css={styles.image}
          turnOffLazyLoading={true}
        />
      </div>

      <div css={styles.content}>
        <div css={styles.name}>{name}</div>

        <div css={styles.details}>
          <div css={styles.symbol}>{symbol.toUpperCase()}</div>

          <TrendText
            css={styles.percentageChange}
            value={priceChangePercentage24h}
            arrowSpacing={5}
            isColored={true}
          />
        </div>
      </div>
    </LinkCard>
  );
};

export default FeaturedCoinCard;
