import * as React from "react";
import { LinkCard } from "../card";
import { buildCoinUrl } from "../../../../utils/build-url";
import { EventCategory } from "../../../../utils/analytics";
import Image from "../../image/image";
import { formatCurrency } from "../../../../utils/format-number";
import { SiteContext } from "../../../../site-context/site-context";
import { CurrencySymbol } from "../../../../../types/currency";
import TrendText from "../../text/trend-text";
import styles from "./coin-card.styles";

interface CoinCardProps {
  slug: string;
  symbol: string;
  name: string;
  image?: string;
  currentPrice?: number;
  priceChangePercentage24h?: number;
  eventCategory: EventCategory;
}

const CoinCard: React.FC<CoinCardProps> = ({
  slug,
  name,
  symbol,
  image,
  currentPrice,
  priceChangePercentage24h,
  eventCategory,
}) => {
  const {
    currency: [currency],
    usdExchangeRate,
  } = React.useContext(SiteContext);

  return (
    <LinkCard
      href={buildCoinUrl(slug)}
      css={styles.card}
      analytics={{
        category: eventCategory,
        action: `Click - ${name}`,
        label: name,
      }}
    >
      <div css={styles.header}>
        <div css={styles.imageContainer}>
          <Image src={image} alt={name} css={styles.image} />
        </div>

        <div css={styles.symbol}>{symbol.toUpperCase()}</div>
      </div>

      <div css={styles.name}>{name}</div>
      <div css={styles.price}>
        {(currency === CurrencySymbol.USD || currency === CurrencySymbol.AUD) &&
          currency.toUpperCase()}{" "}
        {currentPrice
          ? formatCurrency({
              value: currentPrice * (usdExchangeRate || 1),
              currency,
            })
          : "-"}
      </div>
      <TrendText
        css={styles.percentageChange}
        value={priceChangePercentage24h}
        arrowSpacing={5}
        isColored={true}
      />
    </LinkCard>
  );
};

export default CoinCard;
