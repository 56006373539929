import * as React from "react";
import { useDefiGlobal } from "../../../../hooks/query-hooks/use-defi-global";
import { Card } from "../../../ui-library/cards/card";
import Row from "../../../ui-library/column-system/row";
import {
  formatPercent,
  formatBigCurrency,
} from "../../../../utils/format-number";
import { SiteContext } from "../../../../site-context/site-context";
import TextSkeleton from "../../../ui-library/text/text-skeleton";
import styles from "./market-snapshot.styles";
import SnapshotItem from "./snapshot-item";

const DefiSnapshot: React.FC = () => {
  const {
    currency: [currency],
    usdExchangeRate,
  } = React.useContext(SiteContext);

  const { data, loading } = useDefiGlobal();

  const formatDisplayValue = (value: number) =>
    formatBigCurrency({
      value: value * (usdExchangeRate || 1),
      currency,
    });

  if (loading && !data) {
    return (
      <Card css={styles.card}>
        <Row>
          <SnapshotItem label="DeFi Market Cap">
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="ETH Market Cap">
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="24hr Trading Volume" isBottom={true}>
            <TextSkeleton width={100} />
          </SnapshotItem>
          <SnapshotItem label="DeFi Dominance vs Global" isBottom={true}>
            <TextSkeleton width={100} />
          </SnapshotItem>
        </Row>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  const { defiMarketCap, ethMarketCap, tradingVolume24h, defiDominance } = data;

  return (
    <Card css={styles.card}>
      <Row>
        {defiMarketCap && (
          <SnapshotItem label="DeFi Market Cap">
            {formatDisplayValue(defiMarketCap)}
          </SnapshotItem>
        )}
        {ethMarketCap && (
          <SnapshotItem label="ETH Market Cap">
            {formatDisplayValue(ethMarketCap)}
          </SnapshotItem>
        )}
        {tradingVolume24h && (
          <SnapshotItem label="24hr Trading Volume" isBottom={true}>
            {formatDisplayValue(tradingVolume24h)}
          </SnapshotItem>
        )}
        {defiDominance && (
          <SnapshotItem label="DeFi Dominance vs Global" isBottom={true}>
            {formatPercent({ value: defiDominance })}
          </SnapshotItem>
        )}
      </Row>
    </Card>
  );
};

export default DefiSnapshot;
