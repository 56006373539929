import * as React from "react";
import { useSwipeable } from "react-swipeable";

import { EventCategory } from "../../../utils/analytics";
import Actionable from "../actionable/actionable";
import styles from "./featured-slides.styles";

export interface FeatureSlidesProps {
  items: React.ReactNodeArray;
  eventCategory: EventCategory;
}

const FeatureSlides: React.FC<FeatureSlidesProps> = ({
  items,
  eventCategory,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [swipingDeltaX, setSwipingDeltaX] = React.useState<number | undefined>(
    undefined
  );
  const [swipingDeltaY, setSwipingDeltaY] = React.useState<number | undefined>(
    undefined
  );

  const childrenItems = items.map((item, index) => ({
    component: item,
    key: index,
  }));
  const isHorizontalScroll =
    !!swipingDeltaX &&
    !!swipingDeltaY &&
    Math.abs(swipingDeltaX) > swipingDeltaY / 2;

  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: isHorizontalScroll,
    onSwiping: (eventData) => {
      const deltaX = eventData.deltaX;
      const deltaY = eventData.deltaY;
      setSwipingDeltaX(deltaX);
      setSwipingDeltaY(deltaY);
    },
    onSwiped: () => {
      setSwipingDeltaX(undefined);
      setSwipingDeltaY(undefined);
      if (swipingDeltaX && window) {
        if (swipingDeltaX < -window.innerWidth / 5) {
          if (activeIndex + 1 <= childrenItems.length - 1) {
            setActiveIndex(activeIndex + 1);
          }
        }
        if (swipingDeltaX > window.innerWidth / 5) {
          if (activeIndex - 1 >= 0) {
            setActiveIndex(activeIndex - 1);
          }
        }
      }
    },
  });

  const getStyleByIndex = (key: number) => {
    if (swipingDeltaX && isHorizontalScroll) {
      const style: React.CSSProperties = {
        transition: "none",
      };

      // Swipe left, 20px gap size
      if (swipingDeltaX < 0) {
        if (key === activeIndex && key < childrenItems.length - 1) {
          style.left = `${swipingDeltaX}px`;
        }
        if (key === activeIndex + 1 && swipingDeltaX < -20) {
          const abs = Math.abs(swipingDeltaX + 20);
          style.left = `calc(100% - ${abs}px)`;
        }
      }

      // Swipe right, 20px gap size
      if (swipingDeltaX > 0) {
        if (key === activeIndex && key > 0) {
          style.left = `${swipingDeltaX}px`;
        }
        if (key === activeIndex - 1 && swipingDeltaX > 20) {
          const abs = Math.abs(swipingDeltaX - 20);
          style.left = `calc(-100% + ${abs}px)`;
        }
      }
      return style;
    }

    return undefined;
  };

  return (
    <div>
      <div css={styles.slidesContainer}>
        <div css={styles.content} {...handlers}>
          {childrenItems.map((item) => (
            <div
              key={item.key}
              css={[
                styles.slidesItem,
                item.key > activeIndex && styles.hiddenRight,
                item.key < activeIndex && styles.hiddenLeft,
              ]}
              style={getStyleByIndex(item.key)}
            >
              {item.component}
            </div>
          ))}
        </div>
      </div>

      <div css={styles.controlContainer}>
        {childrenItems.map((item, index) => (
          <Actionable
            key={item.key}
            ariaLabel={`Slide-${index}`}
            role="button"
            css={[
              styles.control,
              item.key === activeIndex && styles.controlActive,
            ]}
            onClick={() => setActiveIndex(item.key)}
            analytics={{
              category: eventCategory,
              action: "Click - Slides dot paginator",
              label: "Paginator",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default FeatureSlides;
