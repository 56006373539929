import * as React from "react";
import { LinkCard } from "../card";
import { buildExchangeUrl } from "../../../../utils/build-url";
import { EventCategory } from "../../../../utils/analytics";
import Image from "../../image/image";
import { formatBigCurrency } from "../../../../utils/format-number";
import { SiteContext } from "../../../../site-context/site-context";
import styles from "./exchange-card.styles";

interface ExchangeCardProps {
  slug: string;
  name: string;
  image?: string;
  tradeVolume24hBtc?: number;
  eventCategory: EventCategory;
}

const ExchangeCard: React.FC<ExchangeCardProps> = ({
  slug,
  name,
  image,
  tradeVolume24hBtc,
  eventCategory,
}) => {
  const {
    currency: [currency],
    btcExchangeRate,
  } = React.useContext(SiteContext);

  return (
    <LinkCard
      href={buildExchangeUrl(slug)}
      css={styles.card}
      analytics={{
        category: eventCategory,
        action: `Click - ${name}`,
        label: name,
      }}
    >
      <div css={styles.imageContainer}>
        <Image src={image} alt={name} css={styles.image} />
      </div>

      <div css={styles.name}>{name}</div>

      <div css={styles.percentageChange}>
        Vol{" "}
        {tradeVolume24hBtc
          ? formatBigCurrency({
              value: tradeVolume24hBtc * (btcExchangeRate || 1),
              currency,
            })
          : "-"}
      </div>
    </LinkCard>
  );
};

export default ExchangeCard;
