import * as React from "react";
import { useCoinList } from "../../../../hooks/query-hooks/use-coin-list";
import Row from "../../../ui-library/column-system/row";
import Column from "../../../ui-library/column-system/column";
import CoinCard from "../../../ui-library/cards/coin-card/coin-card";
import { EventCategory } from "../../../../utils/analytics";
import MainSection from "../../../ui-library/main-section/main-section";
import { CoinListItemResult } from "../../../../../types/api-results/coin-list-item";

interface TopCoinCardsProps {
  isDefi?: boolean;
  staticData?: CoinListItemResult[];
}

const TopCoinCards: React.FC<TopCoinCardsProps> = ({ isDefi, staticData }) => {
  const { data } = useCoinList({
    staticData,
    pageSize: 5,
    isDefi,
  });

  const heading = isDefi ? "DeFi tokens" : "Coins + Tokens";
  const seeAllUrl = isDefi ? "/coins/defi/" : "/coins/";

  if (!data) {
    return null;
  }

  return (
    <MainSection
      heading={heading}
      subHeading="Based on market cap rank"
      seeAllUrl={seeAllUrl}
      eventCategory={EventCategory.Home}
    >
      <Row isInline={true}>
        {data.map((coin) => (
          <Column
            key={coin.id}
            isInline={true}
            inlineWidth={160}
            spanLg={2.4}
            isNarrowMargin={true}
          >
            <CoinCard {...coin} eventCategory={EventCategory.Home} />
          </Column>
        ))}
      </Row>
    </MainSection>
  );
};

export default TopCoinCards;
