import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import { Colors } from "../design-tokens/colors";
import { Fonts } from "../design-tokens/fonts";
import { grid } from "../design-tokens/grid";
import Link from "../link/link";
import MarginBox from "../margin-box/margin-box";
import Heading from "../text/heading";
import Text from "../text/text";

interface MainSectionProps {
  heading: string;
  subHeading: string;
  seeAllUrl: string;
  eventCategory: EventCategory;
}

const MainSection: React.FC<MainSectionProps> = ({
  heading,
  subHeading,
  seeAllUrl,
  children,
  eventCategory,
}) => {
  return (
    <>
      <MarginBox margin={{ bottom: grid(2) }}>
        <Heading size="large" margin={{ bottom: grid(1) }}>
          {heading}
        </Heading>

        <Text
          component="div"
          textSize={14}
          textColor={Colors.MidGrey}
          weight={Fonts.Weight.SemiBold}
          css={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text margin={{ right: grid(2) }}>{subHeading}</Text>

          <Link
            to={seeAllUrl}
            analytics={{
              category: eventCategory,
              action: "Click - See all",
              label: "See all",
            }}
          >
            See all
          </Link>
        </Text>
      </MarginBox>

      {children}
    </>
  );
};

export default MainSection;
