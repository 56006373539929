import { CSSObject } from "@emotion/react";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const pageNav: CSSObject = {
  display: "flex",

  "> div:first-of-type": {
    flex: 1,
    marginRight: grid(2),
  },

  "> div:last-of-type": {
    [Device.DesktopTablet]: {
      width: grid(30),
    },
  },
};

const styles = {
  pageNav,
};

export default styles;
