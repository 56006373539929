import * as React from "react";
import { navigate } from "gatsby";
import { CoinListItemResult } from "../../../../types/api-results/coin-list-item";
import { ExchangeListItemResult } from "../../../../types/api-results/exchange-list-item";
import { EventCategory } from "../../../utils/analytics";
import Container from "../../ui-library/container/container";
import ItemPicker from "../../ui-library/item-picker/item-picker";
import { SiteContext } from "../../../site-context/site-context";
import MarginBox from "../../ui-library/margin-box/margin-box";
import Search from "../../search/search";
import { grid } from "../../ui-library/design-tokens/grid";
import DefiSnapshot from "./market-snapshot/defi-snapshot";
import CoinMarketSnapshot from "./market-snapshot/coin-market-snapshot";
import TopCoinCards from "./top-coins-cards/top-coin-cards";
import TopExchangeCards from "./top-exchange-cards/top-exchange-cards";
import styles from "./markets.styles";
import FeaturedCoins from "./featured-coins/featured-coins";

interface MarketsProps {
  isDefi?: boolean;
  staticData: {
    allCoinListItem?: {
      nodes: CoinListItemResult[];
    };
    allExchangeListItem?: {
      nodes: ExchangeListItemResult[];
    };
  };
}

const Markets: React.FC<MarketsProps> = ({ isDefi, staticData }) => {
  const { isMobile } = React.useContext(SiteContext);

  return (
    <Container>
      <MarginBox
        css={styles.pageNav}
        margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
      >
        <ItemPicker
          itemPickerName="marketsType"
          activeItemValue={isDefi ? "defi" : "all"}
          items={[
            { label: isMobile ? "All" : "All Markets", value: "all" },
            { label: isMobile ? "DeFi" : "DeFi Market", value: "defi" },
          ]}
          isUniWidth={true}
          onItemClick={() => navigate(isDefi ? "/" : "/defi-market/")}
          eventCategory={EventCategory.Home}
        />

        <Search />
      </MarginBox>

      {isDefi ? <DefiSnapshot /> : <CoinMarketSnapshot />}

      <MarginBox
        margin={{ desktop: { bottom: grid(2) }, mobile: { bottom: grid(3) } }}
      >
        <FeaturedCoins isDefi={isDefi} />
      </MarginBox>

      <MarginBox
        margin={{
          desktop: { bottom: grid(2.5) },
          mobile: { bottom: grid(1.5) },
        }}
      >
        <TopCoinCards
          isDefi={isDefi}
          staticData={staticData.allCoinListItem?.nodes}
        />
      </MarginBox>

      <MarginBox
        margin={{
          desktop: { bottom: grid(2.5) },
          mobile: { bottom: grid(1.5) },
        }}
      >
        <TopExchangeCards
          isDex={isDefi}
          orderBy={isDefi ? "volume" : "trust_score"}
          staticData={staticData.allExchangeListItem?.nodes}
        />
      </MarginBox>
    </Container>
  );
};

export default Markets;
