export const FEATURED_COINS = [
  {
    id: "polkadot",
    slug: "polkadot",
    symbol: "dot",
    name: "Polkadot",
    image: "/images/polkadot.png",
  },
  {
    id: "cardano",
    slug: "cardano",
    symbol: "ada",
    name: "Cardano",
    image: "/images/cardano.png",
  },
  {
    id: "stellar",
    slug: "stellar",
    symbol: "xlm",
    name: "Stellar",
    image: "/images/stellar.png",
  },
];

export const FEATURED_DEFI_COINS = [
  {
    id: "republic-protocol",
    slug: "republic-protocol",
    symbol: "ren",
    name: "REN",
    image: "/images/ren.png",
  },
  {
    id: "sushi",
    slug: "sushi",
    symbol: "sushi",
    name: "Sushi",
    image: "/images/sushi.png",
  },
  {
    id: "api3",
    slug: "api3",
    symbol: "api3",
    name: "API3",
    image: "/images/api3.png",
  },
];
