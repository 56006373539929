import { CSSObject } from "@emotion/react";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { Colors } from "../../../ui-library/design-tokens/colors";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const sectionWithPadding: CSSObject = {
  [Device.Mobile]: {
    paddingBottom: grid(2),
  },
};

const heading: CSSObject = {
  fontSize: 11,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  whiteSpace: "nowrap",
  marginBottom: grid(0.5),

  [Device.Desktop]: {
    fontSize: 14,
  },

  [Device.Tablet]: {
    fontSize: 12,
  },
};

const value: CSSObject = {
  fontSize: 14,
  fontWeight: Fonts.Weight.Bold,

  [Device.Desktop]: {
    fontSize: 20,
  },

  [Device.Tablet]: {
    fontSize: 16,
  },
};

const styles = {
  sectionWithPadding,
  heading,
  value,
};

export default styles;
