import * as React from "react";
import { getTrendColor } from "../../../utils/styles/get-trend-color";
import Arrow, { ArrowSize } from "./arrow";

interface TrendArrowByValueProps {
  value?: number | null;
  size?: ArrowSize;
  marginRight?: number;
}

const TrendArrowByValue: React.FC<TrendArrowByValueProps> = ({
  value,
  size,
  marginRight,
}) => {
  if (!value) {
    return null;
  }
  const direction = value > 0 ? "up" : "down";
  const color = getTrendColor(value);

  return (
    <Arrow
      size={size}
      color={color}
      direction={direction}
      marginRight={marginRight}
    />
  );
};

export default TrendArrowByValue;
