import { CSSObject } from "@emotion/react";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const card: CSSObject = {
  padding: grid(2),
  marginBottom: grid(4),

  [Device.DesktopTablet]: {
    padding: `${grid(2)} ${grid(3.5)} ${grid(2.5)}`,
  },
};

const styles = {
  card,
};

export default styles;
