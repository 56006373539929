import { CSSObject } from "@emotion/react";
import { transparentize } from "polished";
import { Colors } from "../design-tokens/colors";
import { grid } from "../design-tokens/grid";
import { Device } from "../design-tokens/media-queries";

const slidesContainer: CSSObject = {
  padding: grid(2),
  margin: grid(-2),
  overflow: "hidden",
};

const content: CSSObject = {
  position: "relative",
};

const slidesItem: CSSObject = {
  position: "relative",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  transition: "all 0.3s linear",
};

const hiddenLeft: CSSObject = {
  position: "absolute",
  left: "calc(-100% - 20px)",
  opacity: 0,
};

const hiddenRight: CSSObject = {
  position: "absolute",
  left: "calc(100% + 20px)",
  opacity: 0,
};

const controlContainer: CSSObject = {
  display: "flex",
  justifyContent: "center",

  [Device.DesktopTablet]: {
    justifyContent: "flex-end",
  },
};

const control: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(3),
  height: grid(4),
  padding: 0,
  border: 0,
  WebkitAppearance: "none",
  WebkitTapHighlightColor: "transparent",
  backgroundColor: "transparent",
  cursor: "pointer",

  // To even the bottom touchable space
  marginBottom: grid(-1),

  "&:active, &:focus": {
    outline: "0",
  },

  "&::before": {
    content: `''`,
    display: "block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: transparentize(0.8, Colors.MidGrey),
    opacity: 0.3,
  },
};

const controlActive: CSSObject = {
  "&::before": {
    backgroundColor: Colors.Blue,
    opacity: 1,
  },
};

const styles = {
  slidesContainer,
  content,
  slidesItem,
  hiddenLeft,
  hiddenRight,
  controlContainer,
  control,
  controlActive,
};

export default styles;
