import * as React from "react";
import { formatPercent } from "../../../utils/format-number";
import { getTrendColor } from "../../../utils/styles/get-trend-color";
import { ArrowSize } from "../arrow/arrow";
import TrendArrowByValue from "../arrow/trend-arrow-by-value";

export interface TrendTextProps {
  value?: number | null;
  arrowSpacing?: number;
  arrowSize?: ArrowSize;
  isColored?: boolean;
  className?: string;
}

const TrendText: React.FC<TrendTextProps> = ({
  value,
  arrowSpacing = 5,
  arrowSize,
  isColored,
  className,
}) => {
  const formattedValue = formatPercent({ value: Math.abs(value || 0) }) || "-";
  const color = isColored ? getTrendColor(value) : undefined;

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        color,
      }}
      className={className}
    >
      <TrendArrowByValue
        value={value}
        size={arrowSize}
        marginRight={arrowSpacing}
      />
      {formattedValue}
    </div>
  );
};

export default TrendText;
