import { CSSObject } from "@emotion/react";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const hiddenDesktop: CSSObject = {
  [Device.DesktopTablet]: {
    display: "none",
  },
};

const hiddenMobile: CSSObject = {
  [Device.Mobile]: {
    display: "none",
  },
};

const styles = {
  hiddenDesktop,
  hiddenMobile,
};

export default styles;
