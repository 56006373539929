import * as React from "react";
import Column from "../../../ui-library/column-system/column";
import styles from "./snapshot-item.styles";

interface SnapshotItemProps {
  label: string;
  isBottom?: boolean;
}

const SnapshotItem: React.FC<SnapshotItemProps> = ({
  label,
  isBottom,
  children,
}) => (
  <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6} isNoMarginBottom={true}>
    <div css={!isBottom && styles.sectionWithPadding}>
      <div css={styles.heading}>{label}</div>
      <div css={styles.value}>{children}</div>
    </div>
  </Column>
);

export default SnapshotItem;
