import { CSSObject } from "@emotion/react";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { Fonts } from "../../design-tokens/fonts";

const card: CSSObject = {
  display: "flex",
  flexDirection: "column",
  minHeight: "180px",
  padding: grid(1.5),
};

const imageContainer: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(4),
  height: grid(4),
  marginBottom: grid(1.5),
};

const image: CSSObject = {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
};

const name: CSSObject = {
  color: Colors.Black,
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(2),
};

const percentageChange: CSSObject = {
  color: Colors.Black,
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  marginTop: "auto",
  marginBottom: 0,
};

const styles = {
  card,
  imageContainer,
  image,
  name,
  percentageChange,
};

export default styles;
