import * as React from "react";
import Row from "../../../ui-library/column-system/row";
import Column from "../../../ui-library/column-system/column";
import { EventCategory } from "../../../../utils/analytics";
import { CoinListItemResult } from "../../../../../types/api-results/coin-list-item";
import { useExchangeList } from "../../../../hooks/query-hooks/use-exchange-list";
import ExchangeCard from "../../../ui-library/cards/exchange-card/exchange-card";
import MainSection from "../../../ui-library/main-section/main-section";

interface TopExchangeCardsProps {
  isDex?: boolean;
  orderBy?: "volume" | "trust_score";
  staticData?: CoinListItemResult[];
}

const TopExchangeCards: React.FC<TopExchangeCardsProps> = ({
  isDex,
  orderBy,
  staticData,
}) => {
  const { data, loading } = useExchangeList({
    staticData,
    size: 5,
    orderBy,
    isDex,
  });

  const heading = isDex ? "DEX exchanges" : "Exchanges";
  const seeAllUrl = isDex ? "/exchanges/dex/" : "/exchanges/";

  return (
    <MainSection
      heading={heading}
      subHeading="Based on 24h volume"
      seeAllUrl={seeAllUrl}
      eventCategory={EventCategory.Home}
    >
      <Row isInline={true}>
        {data?.map((coin) => (
          <Column
            key={coin.id}
            isInline={true}
            inlineWidth={160}
            spanLg={2.4}
            isNarrowMargin={true}
          >
            <ExchangeCard {...coin} eventCategory={EventCategory.Home} />
          </Column>
        ))}
      </Row>
    </MainSection>
  );
};

export default TopExchangeCards;
