import * as React from "react";
import {
  FEATURED_COINS,
  FEATURED_DEFI_COINS,
} from "../../../../../data/featured-coins/featured-coins";
import { useCoinList } from "../../../../hooks/query-hooks/use-coin-list";
import { EventCategory } from "../../../../utils/analytics";
import FeaturedCoinCard from "../../../ui-library/cards/featured-coin-card/featured-coin-card";
import Column from "../../../ui-library/column-system/column";
import Row from "../../../ui-library/column-system/row";
import { Colors } from "../../../ui-library/design-tokens/colors";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { grid } from "../../../ui-library/design-tokens/grid";
import FeatureSlides from "../../../ui-library/featured-slides/featured-slides";
import Text from "../../../ui-library/text/text";
import styles from "./featured-coins.styles";

interface FeaturedCoinsProps {
  isDefi?: boolean;
}

const FeaturedCoins: React.FC<FeaturedCoinsProps> = ({ isDefi }) => {
  const eventCategory = EventCategory.FeaturedCoins;

  const featuredCoins = !isDefi ? FEATURED_COINS : FEATURED_DEFI_COINS;
  const ids = featuredCoins.map((coin) => coin.id);

  const { data } = useCoinList({ ids });
  const hashPriceById = data?.reduce(
    (change: Record<string, number | undefined>, coin) => {
      if (!change[coin.id]) {
        change[coin.id] = coin.priceChangePercentage24h;
      }

      return change;
    },
    {}
  );

  const items = React.useMemo(
    () =>
      featuredCoins.map((coin) => (
        <FeaturedCoinCard
          key={coin.slug}
          {...coin}
          priceChangePercentage24h={hashPriceById?.[coin.id]}
          eventCategory={eventCategory}
        />
      )),
    [isDefi, hashPriceById]
  );

  return (
    <div>
      <Text
        component="div"
        textSize={14}
        textColor={Colors.MidGrey}
        weight={Fonts.Weight.SemiBold}
        margin={{ bottom: grid(2) }}
      >
        Featured
      </Text>

      <div css={styles.hiddenMobile}>
        <Row>
          {featuredCoins.map((coin) => (
            <Column key={coin.slug} spanLg={4} spanMd={4}>
              <FeaturedCoinCard
                {...coin}
                priceChangePercentage24h={hashPriceById?.[coin.id]}
                eventCategory={eventCategory}
              />
            </Column>
          ))}
        </Row>
      </div>
      <div css={styles.hiddenDesktop}>
        <FeatureSlides items={items} eventCategory={eventCategory} />
      </div>
    </div>
  );
};

export default FeaturedCoins;
