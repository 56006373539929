import { CSSObject } from "@emotion/react";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { Fonts } from "../../design-tokens/fonts";

const card: CSSObject = {
  display: "flex",
  alignItems: "center",
  height: grid(10),
  padding: grid(2),
  paddingRight: grid(2.5),
};

const imageContainer: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(6),
  height: grid(6),
  marginRight: grid(2),
};

const image: CSSObject = {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
};

const content: CSSObject = {
  flex: 1,
};

const details: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
};

const symbol: CSSObject = {
  color: Colors.MidGrey,
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
};

const name: CSSObject = {
  color: Colors.Black,
  lineHeight: "22px",
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(0.5),
};

const percentageChange: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  marginTop: "auto",
  marginBottom: 0,
};

const styles = {
  card,
  imageContainer,
  image,
  content,
  details,
  symbol,
  name,
  percentageChange,
};

export default styles;
